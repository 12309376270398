import React from 'react'

import Layout from '../components/layout'
import { Container, Row, Col } from 'react-bootstrap'
import { SectionGray, SingleSection, SingleSectionGray } from '../components/sections'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBolt, faSearch, faCogs } from '@fortawesome/free-solid-svg-icons'

const Progetto = () => (
<Layout frame='projNav'>

    <SingleSectionGray id='sigrid'>
        <Col md={12} className='text-center heading-section'>
            <h3>SIGRId</h3>

            <p>L'IAS collabora con la SUPSI al fine di assicurare la ricerca, lo sviluppo e supportare la formazione nel
                campo dei sistemi di controllo interno, gestione dei rischi legati ai processi di produzione e analisi
                socioeconomica, mediante approcci e metodologie innovative.
            </p>
            <p>
                Il percorso di informatizzazione, che le parti si prefiggono di affrontare, consentirà di collegare l’attività
                di produzione interna generata dalle richieste degli assicurati, alla gestione dei rischi diretti in primo luogo
                e successivamente all’analisi degli effetti che indirettamente queste possono generare sul territorio.
            </p>
        </Col>
    </SingleSectionGray>

    <SingleSection id='convenzione'>
        <Col md={12} className='text-center heading-section'>
            <h3>Convenzione IAS-SUPSI</h3>
            <p>La convenzione IAS-SUPSI si svolge nel triennio 2018-2021 e prevede tre compiti.
            </p>

            <h4>Compito 1</h4>
            <p>Creazione di un sistema di controllo interno per la gestione dei rischi, con una logica orientata
                alla digitalizzazione dei processi di produzione dell'IAS per agevolare un controllo semiautomatizzato e lo
                scambio di dati tra gli uffici.
            </p>

            <h4>Compito 2</h4>
            <p>
                Costruzione di un modello di monitoraggio e analisi integrato delle richieste e delle prestazioni
                legate ai beneficiari del sistema di sicurezza negli ambiti delle prestazioni, dei contributi e dell'AI.
            </p>

            <h4>Compito 3</h4>
            <p>
                Attività di formazione orientata allo sviluppo di competenze atte a garantire un supporto
                nell'utilizzo a regime degli strumenti di lavoro sviluppati nell'ambito della convenzione.
            </p>
        </Col>
    </SingleSection>

    <SectionGray id='elem'>
        <Container>
            <Row>
                <Col md={12} className='text-center heading-section'>
                    <h3>Elementi fondamentali del sistema</h3>
                    <p>Il sistema agisce su tre elementi fondamentali: processi, rischi e controlli.</p>
                    <p>
                        Nello sviluppo del SCI, inizialmente sono definiti i processi, poi valutati i rischi all’interno degli stessi ed
                        in ultimo luogo creati i controlli per minimizzare tali rischi. Non si possono valutare i rischi senza definire
                        prima i processi, come non si possono stabilire i controlli senza aver precedentemente individuato i rischi
                        all’interno dei processi.
                    </p>
                </Col>
            </Row>
        </Container>
        <Container>
            <Row className='text-center'>
                <Col md={4} sm={12}>
                    <div className='service'>
                        <span className='icon'>
                            <FontAwesomeIcon icon={faCogs} />
                        </span>
                        <h3>Processi</h3>
                        <p>Sono insiemi organizzati di attività svolte della società che generano valore, destinati ad un soggetto
                            interno o esterno alla società.
                        </p>
                    </div>
                </Col>

                <Col md={4} sm={12}>
                    <div className='service'>
                        <span className='icon'>
                            <FontAwesomeIcon icon={faBolt} />
                        </span>
                        <h3>Rischi</h3>
                        <p>Il rischio generalmente è definito come un fattore che compromette il raggiungimento di un obiettivo, provoca
                            incertezza. Esso deve essere valutato in relazione alla combinazione fra la probabilità d’insorgenza e l’impatto
                            che genera qualora dovesse verificarsi.
                        </p>
                    </div>
                </Col>

                <Col md={4} sm={12}>
                    <div className='service'>
                        <span className='icon'>
                            <FontAwesomeIcon icon={faSearch} />
                        </span>
                        <h3>Controlli</h3>
                        <p>Sono le attività in essere nell'azienda che permettono di ridurre la possibilità di manifestarsi dei rischi.</p>
                    </div>
                </Col>
            </Row>
        </Container>
    </SectionGray>
</Layout>

)

export default Progetto
